import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'

import getAuthorProperties from './getAuthorProperties'
import ImageMeta from './ImageMeta'
import config from '../../../utils/siteConfig'
import { urlFor } from "./SanityImageUrl"

import { tags as tagsHelper } from '@tryghost/helpers'

const ArticleMetaSanity = ({ data, settings, canonical, type }) => {
    const sanityPost = data
    settings = settings.allSanitySiteSettings.edges[0].node

    const author = getAuthorProperties(sanityPost.author)
    const publicTags = _.map(tagsHelper(sanityPost, { visibility: `public`, fn: tag => tag }), `name`)
    const primaryTag = publicTags[0] || ``
    const shareImage = sanityPost.mainImage ? urlFor(sanityPost.mainImage).width(config.shareImageWidth).url() : settings.coverImage ? urlFor(settings.coverImage).width(config.shareImageWidth).url() : null
    const publisherLogo = urlFor(settings.logo).width(config.shareSquareImageSize).height(config.shareSquareImageSize).url()

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": type == 'news' ? 'NewsArticle' : `Article`,
        author: {
            "@type": `Person`,
            name: author.name,
            image: author.image ? urlFor(author.image).width(config.shareSquareImageSize).height(config.shareSquareImageSize).url() : undefined,
            sameAs: author.sameAsArray ? author.sameAsArray : undefined,
        },
        keywords: publicTags.length ? publicTags.join(`, `) : undefined,
        headline: sanityPost.title || sanityPost.seo.metaTitle || settings.title,
        url: canonical,
        datePublished: sanityPost.publishedAt,
        dateModified: sanityPost.updatedAt,
        image: shareImage ? {
            "@type": `ImageObject`,
            url: shareImage,
            width: config.shareImageWidth,
            height: config.shareImageHeight,
        } : undefined,
        publisher: {
            "@type": `Organization`,
            name: settings.title,
            logo: {
                "@type": `ImageObject`,
                url: publisherLogo,
                width: config.shareSquareImageSize,
                height: config.shareSquareImageSize,
            },
        },
        description: sanityPost.seo.metaDescription || sanityPost.excerpt,
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": canonical,
        },
    }

    return (
        <>
            <Helmet>
                <title>{sanityPost.seo.metaTitle || sanityPost.title}</title>
                <meta name="description" content={sanityPost.seo.metaDescription || sanityPost.excerpt} />
                <link rel="canonical" href={canonical} />

                <meta property="og:site_name" content={settings.title} />
                <meta property="og:type" content="article" />
                <meta property="og:title"
                    content={
                        sanityPost.seo.ogTitle ||
                        sanityPost.seo.metaTitle ||
                        sanityPost.title
                    }
                />
                <meta property="og:description"
                    content={
                        sanityPost.seo.ogDescription ||
                        sanityPost.seo.metaDescription ||
                        sanityPost.excerpt
                    }
                />
                <meta property="og:url" content={canonical} />
                <meta property="article:published_time" content={sanityPost.publishedAt} />
                <meta property="article:modified_time" content={sanityPost.updatedAt} />
                {publicTags.map((keyword, i) => (<meta property="article:tag" content={keyword} key={i} />))}
                {author.facebook && <meta property="article:author" content={author.facebook} />}

                <meta name="twitter:title"
                    content={
                        sanityPost.seo.twitterTitle ||
                        sanityPost.seo.metaTitle ||
                        sanityPost.title
                    }
                />
                <meta name="twitter:description"
                    content={
                        sanityPost.twitterDescription ||
                        sanityPost.excerpt ||
                        sanityPost.seo.metaDescription
                    }
                />
                <meta name="twitter:url" content={canonical} />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content={author.name} />
                {primaryTag && <meta name="twitter:label2" content="Filed under" />}
                {primaryTag && <meta name="twitter:data2" content={primaryTag} />}

                {author.twitter && <meta name="twitter:site" content={author.twitter} />}
                {author.twitter && <meta name="twitter:creator" content={author.twitter.split('/')[-1]} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3736182746919507"
     crossorigin="anonymous"></script>
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

ArticleMetaSanity.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        publishedAt: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        seo: PropTypes.shape({
            metaTitle: PropTypes.string,
            metaDescription: PropTypes.string,
        }),
        author: PropTypes.object.isRequired,
        mainImage: PropTypes.object,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                slug: PropTypes.string,
                visibility: PropTypes.string,
            })
        ),
        primaryTag: PropTypes.shape({
            name: PropTypes.string,
        }),
        ogTitle: PropTypes.string,
        ogDescription: PropTypes.string,
        twitterTitle: PropTypes.string,
        twitterDescription: PropTypes.string,
        excerpt: PropTypes.string.isRequired,
    }).isRequired,
    settings: PropTypes.shape({
        logo: PropTypes.object,
        title: PropTypes.string,
        twitter: PropTypes.string,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
}

const ArticleMetaQuery = props => (
    <StaticQuery
        query={graphql`
            query SettingsArticleMeta {
                allSanitySiteSettings {
                    edges {
                        node {
                            ...SanitySiteSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <ArticleMetaSanity settings={data} {...props} />}
    />
)

export default ArticleMetaQuery