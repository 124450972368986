import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'

import ImageMeta from './ImageMeta'
import config from '../../../utils/siteConfig'
import { urlFor } from "./SanityImageUrl"
0

const WebsiteMeta = ({ data, settings, canonical, title, description, image, type }) => {
    settings = settings.allSanitySiteSettings.edges[0].node
    const twitterUrl = settings.socialmedialinks?.twitter

    const publisherLogo = urlFor(settings.logo).width(config.shareSquareImageSize).height(config.shareSquareImageSize).url()
    let shareImage = image != null ? urlFor(image).width(config.shareImageWidth).url() : settings.coverImage ? urlFor(settings.coverImage).width(config.shareImageWidth).url() : null

    description = data.seo?.metaDescription || description || data.description || config.siteDescriptionMeta || settings.seo.metaDescription
    title = `${data.seo?.metaTitle || data.title || title || data.name}`// - ${settings.seo.metaTitle}`

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": type,
        url: canonical,
        image: shareImage ?
            {
                "@type": `ImageObject`,
                url: shareImage,
                width: config.shareImageWidth,
                height: config.shareImageHeight,
            } : undefined,
        publisher: {
            "@type": `Organization`,
            name: settings.title,
            logo: {
                "@type": `ImageObject`,
                url: publisherLogo,
                width: config.shareSquareImageSize,
                height: config.shareSquareImageSize,
            },
        },
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": canonical,
        },
        description,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
                <meta property="og:site_name" content={settings.title} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:url" content={canonical} />
                {twitterUrl && <meta name="twitter:site" content={twitterUrl} />}
                {twitterUrl && <meta name="twitter:creator" content={twitterUrl.split('/')[-1]} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3736182746919507"
     crossorigin="anonymous"></script>
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

WebsiteMeta.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        seo: PropTypes.shape({
            metaTitle: PropTypes.string,
            metaDescription: PropTypes.string,
        }),
        name: PropTypes.string,
        mainImage: PropTypes.object,
        description: PropTypes.string,
        bio: PropTypes.string,
        image: PropTypes.object,
    }).isRequired,
    settings: PropTypes.shape({
        logo: PropTypes.object,
        description: PropTypes.string,
        title: PropTypes.string,
        allSanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    type: PropTypes.oneOf([`WebSite`, `Series`]).isRequired,
}

const WebsiteMetaQuery = props => (
    <StaticQuery
        query={graphql`
            query SettingsWebsiteMeta {
                allSanitySiteSettings {
                    edges {
                        node {
                            ...SanitySiteSettingsFields
                        }
                    }
                }
            }
            
        `}
        render={data => <WebsiteMeta settings={data} {...props} />}
    />
)

export default WebsiteMetaQuery
