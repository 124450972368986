import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'

import ImageMeta from './ImageMeta'
import getAuthorProperties from './getAuthorProperties'
import config from '../../../utils/siteConfig'
import { urlFor } from "./SanityImageUrl"

const AuthorMeta = ({ data, settings, canonical }) => {
    settings = settings.allSanitySiteSettings.edges[0].node

    const author = getAuthorProperties(data)
    const shareImage = urlFor(author.image).width(config.shareSquareImageSize).height(config.shareSquareImageSize).url()
    const title = `${data.name} - ${settings.title}`
    const description = data.seo.metaDescription

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": `Person`,
        name: data.name,
        sameAs: author.sameAsArray ? author.sameAsArray : undefined,
        url: canonical,
        image: shareImage ? {
            "@type": `ImageObject`,
            url: shareImage,
            width: config.shareSquareImageSize,
            height: config.shareSquareImageSize,
        } : undefined,
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": canonical,
        },
        description,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
                <meta property="og:site_name" content={settings.title} />
                <meta property="og:type" content="profile" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:url" content={canonical} />
                {settings.twitterUrl && <meta name="twitter:site" content={data.social} />}
                {settings.twitterUrl && <meta name="twitter:creator" content={settings.socialmedi.split('/')[-1]} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

AuthorMeta.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        bio: PropTypes.string,
        image: PropTypes.object,
        website: PropTypes.object,
        twitter: PropTypes.string,
        facebook: PropTypes.string,
    }).isRequired,
    settings: PropTypes.shape({
        title: PropTypes.string,
        twitter: PropTypes.string,
        description: PropTypes.string,
        allSanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
}

const AuthorMetaQuery = props => (
    <StaticQuery
        query={graphql`
            query sanitySettingsAuthorMeta {
                allSanitySiteSettings {
                    edges {
                        node {
                            ...SanitySiteSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <AuthorMeta settings={data} {...props} />}
    />
)

export default AuthorMetaQuery
