import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import config from '../../../utils/siteConfig'
import ArticleMeta from './ArticleMeta'
import WebsiteMeta from './WebsiteMeta'
import AuthorMeta from './AuthorMeta'

/**
* MetaData will generate all relevant meta data information incl.
* JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
*
*/
const MetaData = ({
    data,
    settings,
    title,
    description,
    image,
    location,
}) => {
    const canonical = new URL(location.pathname, config.siteUrl).href
    const { sanityPost, sanityCategory, sanityAuthor, sanityPage } = data
    settings = settings.allSanitySiteSettings.edges[0].node

    if (sanityPost) {
        return (
            <ArticleMeta
                data={sanityPost}
                canonical={canonical}
                image={sanityPost.mainImage}
                type={sanityPost.categories[0].type}
            />
        )
    } else if (sanityCategory) {
        return (
            <WebsiteMeta
                data={sanityCategory}
                canonical={canonical}
                image={sanityCategory.image}
                type="Series"
            />
        )
    } else if (sanityAuthor) {
        return (
            <AuthorMeta
                data={sanityAuthor}
                canonical={canonical}
                image={sanityAuthor.image}
            />
        )
    } else if (sanityPage) {
        return (
            <WebsiteMeta
                data={sanityPage}
                canonical={canonical}
                type="WebSite"
            />
        )
    } else {
        title = title || settings.seo.metaTitle || config.siteTitleMeta
        description = description || settings.seo.metaDescription || config.siteDescriptionMeta
        image = image || settings.coverImage || null

        return (
            <WebsiteMeta
                data={{}}
                canonical={canonical}
                title={title}
                description={description}
                image={image}
                type="WebSite"
            />
        )
    }
}

MetaData.defaultProps = {
    data: {},
}

MetaData.propTypes = {
    data: PropTypes.shape({
        sanityPost: PropTypes.object,
        sanityTag: PropTypes.object,
        sanityAuthor: PropTypes.object,
        sanityPage: PropTypes.object,
    }).isRequired,
    settings: PropTypes.shape({
        allSanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
}

const MetaDataQuery = props => (
    <StaticQuery
        query={graphql`
            query sanitySettingsMetaData {
                allSanitySiteSettings {
                    edges {
                        node {
                            ...SanitySiteSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <MetaData settings={data} {...props} />}
    />
)

export default MetaDataQuery
